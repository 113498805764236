<template>
  <v-container grid-list-xl>
    <v-layout
      fluid
      pt-0
      wrap>
      <v-flex xs12>
        <app-card
          heading="Drivers Schedule"
          col-classes="xl12 lg12 md12 sm12 xs12"
          custom-classes="mb-0"
        >
          <v-layout
            row
            wrap>
            <!-- <vFlex
              md6
              xs12>
              <brands-filter
                v-if="brandList.length"
                :brands-id="filters.brandsFilter"
                :brand-list="brandList"
                :on-change-brands="onChangeBrands"
              />
            </vFlex> -->
            <vFlex
              md3
              sm6
              xs12>
              <v-select
                :items="Object.values(availabilityFilterDictionary)"
                v-model="availabilityFilter"
                :disabled="loading"
                clearable
                item-value="value"
                item-text="text"
                label="Availability"
                multiple
                hide-details
                background-color="white"
                class="bordered-input"
                @change="changeAvailabilityFilter"
              />
            </vFlex>
            <vFlex
              md3
              sm6
              xs12>
              <v-select
                v-model="filters.brandsFilter"
                :disabled="loading"
                :items="brandList"
                item-text="name"
                item-value="name"
                background-color="white"
                color="white"
                class="bordered-input"
                hide-details
                label="Brand"
              />
            </vFlex>
            <vFlex
              md3
              sm6
              xs12>
              <v-select
                :items="sorting"
                v-model="filters.sortFilter"
                :disabled="loading"
                item-value="name"
                item-text="title"
                background-color="white"
                color="white"
                class="bordered-input"
                hide-details
              />
            </vFlex>
            <vFlex
              md3
              sm6
              xs12>
              <v-select
                :items="regionList"
                v-model="filters.regionsFilter"
                :item-text="(val) => { return `${val.name} id: ${val.id}`}"
                multiple
                clearable
                class="bordered-input"
                label="Region"
                item-value="id"
                hide-details
              />
            </vFlex>
          </v-layout>
          <v-data-table
            :headers="headersTable"
            :items="itemsTable"
            :rows-per-page-items="[100, 200, 500]"
            :loading="loading"
            class="driver-schedule-table mt-3">
            <template v-slot:headers="props">
              <tr>
                <th
                  v-for="(header, index) in props.headers"
                  :key="header.text"
                >
                  <v-icon
                    v-if="header.customSortable"
                    small>arrow_upward</v-icon>
                  <div class="column-layout">
                    <div
                      v-if="index === 0">
                      {{ header.text }}
                    </div>
                    <div
                      v-else
                      :class="`date-cell-header px-2 py-1 ${scheduleSorting.day === header.text ? 'white' : ''}`"
                      @click="() => { setDaySort(header.text) }">
                      {{ header.text }}
                    </div>
                    <div class="row-layout">
                      <div
                        v-if="index > 0"
                        :class="`daytime-cell-header py-1 ${scheduleSorting.day === header.text && scheduleSorting.time === AVAILABILITY_TYPES.DAYTIME ? 'white' : ''}`"
                        @click="() => { setTimeSort(header.text, AVAILABILITY_TYPES.DAYTIME) }">D</div>
                      <div
                        v-if="index > 0"
                        :class="`evening-cell-header py-1 ${scheduleSorting.day === header.text && scheduleSorting.time === AVAILABILITY_TYPES.EVENING ? 'white' : ''}`"
                        @click="() => { setTimeSort(header.text, AVAILABILITY_TYPES.EVENING) }">E</div>
                    </div>
                  </div>
                </th>
              </tr>
            </template>
            <template v-slot:items="props">
              <td
                v-for="(header, index) in headersTable"
                :key="props.item.driver.id + '|' + index"
                class="text-center">
                <div
                  v-if="index > 0"
                  class="row-layout full-height">
                  <div class="daytime-cell-item full-height">
                    <div
                      v-if="props.item.schedule[header.text] && isDaytimeRideInclude(props.item.schedule[header.text].rides) && (!availabilityFilter.length || availabilityFilter.includes(availabilityFilterDictionary.booked.value))"
                      class="blue full-height">
                      B
                    </div>
                    <div
                      v-else-if="props.item.schedule[header.text] && props.item.schedule[header.text].daytime && (!availabilityFilter.length || availabilityFilter.includes(availabilityFilterDictionary.available.value))"
                      class="green full-height">
                      A
                    </div>
                    <div
                      v-else-if="props.item.schedule[header.text] && !props.item.schedule[header.text].daytime && !isDaytimeRideInclude(props.item.schedule[header.text].rides) && (!availabilityFilter.length || availabilityFilter.includes(availabilityFilterDictionary.unavailable.value))"
                      class="red full-height">
                      U
                    </div>
                    <div
                      v-else
                      class="gray full-height">
                      -
                    </div>
                  </div>
                  <div class="evening-cell-item full-height">
                    <div
                      v-if="props.item.schedule[header.text] && isEveningRideInclude(props.item.schedule[header.text].rides) && (!availabilityFilter.length || availabilityFilter.includes(availabilityFilterDictionary.booked.value))"
                      class="blue full-height">
                      B
                    </div>
                    <div
                      v-else-if="props.item.schedule[header.text] && props.item.schedule[header.text].evening && (!availabilityFilter.length || availabilityFilter.includes(availabilityFilterDictionary.available.value))"
                      class="green full-height">
                      A
                    </div>
                    <div
                      v-else-if="props.item.schedule[header.text] && !props.item.schedule[header.text].evening && !isEveningRideInclude(props.item.schedule[header.text].rides) && (!availabilityFilter.length || availabilityFilter.includes(availabilityFilterDictionary.unavailable.value))"
                      class="red full-height">
                      U
                    </div>
                    <div
                      v-else
                      class="gray full-height">
                      -
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="px-1"
                  style="min-width: 150px;">
                  <router-link
                    :to="`/tables/drivers/${props.item.driver.id}`"
                  >
                    {{ props.item.driver.user.name }}
                  </router-link>
                </div>
              </td>
            </template>
          </v-data-table>
        </app-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import Request from '../../../helpers/request'
import moment from 'moment'
import Brands from '../../../helpers/brands'
import lodash from 'lodash'
import BrandsFilter from 'Components/BrandsFilter'
import DriverSchedule from '../../../helpers/driver-schedule'

export default {
  components: { CrudTable, BrandsFilter },
  mixins: [Request, Brands, DriverSchedule],
  data () {
    const availabilityFilterDictionary = {
      available: {
        text: 'Available',
        value: 'available'
      },
      unavailable: {
        text: 'Unavailable',
        value: 'unavailable'
      },
      booked: {
        text: 'Booked',
        value: 'booked'
      }
    }

    return {
      params: {},
      loading: false,
      filters: {
        sortFilter: null,
        brandsFilter: null,
        regionsFilter: null
      },
      sorting: [
        {
          title: 'Id',
          name: 'id',
          dir: 'asc'
        },
        {
          title: 'First name',
          name: 'firstName',
          dir: 'asc'
        },
        {
          title: 'Created at',
          name: 'createdAt',
          dir: 'asc'
        },
        {
          title: 'Modified at',
          name: 'modifiedAt',
          dir: 'asc'
        },
        {
          title: 'Deleted at',
          name: 'deletedAt',
          dir: 'asc'
        }
      ],
      scheduleSorting: {
        day: null,
        time: null
      },
      items: [],
      brandList: [],
      regionList: [],
      availabilityFilterDictionary,
      availabilityFilter: [
        availabilityFilterDictionary.available.value,
        availabilityFilterDictionary.unavailable.value,
        availabilityFilterDictionary.booked.value
      ],
      isInit: false
    }
  },
  computed: {
    itemsTable () {
      let items = this.items
      if (this.availabilityFilter.length && this.items.length) {
        items = this.items.map(item => {
          const schedule = Object.fromEntries(Object.entries(item.schedule).filter(dayItem => {
            const day = dayItem[1]
            if (day && (this.isDaytimeRideInclude(day.rides) || this.isEveningRideInclude(day.rides)) && this.availabilityFilter.includes(this.availabilityFilterDictionary.booked.value)) {
              return true
            }

            if (day && (day.daytime || day.evening) && this.availabilityFilter.includes(this.availabilityFilterDictionary.available.value)) {
              return true
            }

            if (day && (!day.daytime || !day.evening) && this.availabilityFilter.includes(this.availabilityFilterDictionary.unavailable.value)) {
              return true
            }
            return false
          }))

          if (Object.keys(schedule).length !== 0) {
            return { ...item, schedule }
          }
        }).filter(item => item)
      }

      return items.sort((a, b) => {
        if (this.scheduleSorting.day) {
          const day = this.scheduleSorting.day

          if (!a.schedule[day]) {
            return 1
          }
          if (!b.schedule[day]) {
            return -1
          }

          if (this.availabilityFilter.includes(this.availabilityFilterDictionary.available.value)) {
            return this.availableSort(a, b)
          } else if (this.availabilityFilter.includes(this.availabilityFilterDictionary.booked.value)) {
            return this.bookedSort(a, b)
          } else if (this.availabilityFilter.includes(this.availabilityFilterDictionary.unavailable.value)) {
            return this.unavailableSort(a, b)
          } else {
            return this.availableSort(a, b)
          }
        }

        return 1
      })
    },
    headersTable () {
      let headers = []

      if (this.itemsTable.length) {
        this.itemsTable.forEach(item => {
          Object.keys(item.schedule).forEach(key => {
            if (!headers.some(header => header.text === key)) {
              headers.push({ text: key, sortable: false })
            }
          })
        })
        headers = headers.sort((a, b) => a.text > b.text ? 1 : -1)
        headers.unshift({ text: 'Driver', sortable: true })
      }
      return headers
    }
  },
  watch: {
    params: {
      handler: async function (newValue, oldValue) {
        if (oldValue ? !lodash.isEqual(oldValue, newValue) : false) {
          this.getDriversSchedule(newValue)
        }
      },
      deep: true
    },
    filters: {
      deep: true,
      handler () {
        this.updateParams()
      }
    }
  },
  async beforeMount () {
    for (let i = 0; i < 14; i++) {
      this.headersTable.push(
        { text: moment().add(i, 'days').format('YYYY-MM-DD'), sortable: false }
      )
    }

    try {
      this.brandList = await this.fetchBrands()
      this.request('GET', '/regions?pageSize=9999', {}, ({ data }) => {
        this.regionList = data.data
      })
    } catch (e) {
      throw new Error(e)
    }

    const _filters = { ...this.filters }

    if (this.$route.query.sort) {
      _filters.sortFilter = this.$route.query.sort
    } else {
      _filters.sortFilter = this.sorting[0].name
    }

    if (this.$route.query.brand) {
      _filters.brandsFilter = this.$route.query.brand
    } else {
      _filters.brandsFilter = this.brandList.length ? this.brandList[0].name : null
    }

    if (this.$route.query.regions) {
      _filters.regionsFilter = this.$route.query.regions.split(',').map(region => parseInt(region))
    }

    if (this.$route.query.availability) {
      this.availabilityFilter = this.$route.query.availability.split(',')
    }

    if (this.$route.query.daySort) {
      this.scheduleSorting.day = this.$route.query.daySort
    }

    if (this.$route.query.timeSort) {
      this.scheduleSorting.time = this.$route.query.timeSort
    }

    this.filters = _filters
  },
  methods: {
    availableSort (a, b) {
      const day = this.scheduleSorting.day
      const time = this.scheduleSorting.time
      if (time) {
        return a.schedule[day][time] ? -1 : 1
      } else {
        return a.schedule[day][this.AVAILABILITY_TYPES.DAYTIME] && a.schedule[day][this.AVAILABILITY_TYPES.EVENING] ? -1 : 1
      }
    },
    bookedSort (a, b) {
      const day = this.scheduleSorting.day
      const time = this.scheduleSorting.time
      const isDaytimeIncludeA = this.isDaytimeRideInclude(a.schedule[day].rides)
      const isEveningIncludeA = this.isEveningRideInclude(a.schedule[day].rides)
      const isDaytimeIncludeB = this.isDaytimeRideInclude(b.schedule[day].rides)
      const isEveningIncludeB = this.isEveningRideInclude(b.schedule[day].rides)
      if (time) {
        if (time === this.AVAILABILITY_TYPES.DAYTIME) {
          return isDaytimeIncludeA ? -1 : 1
        } else {
          return isEveningIncludeA ? -1 : 1
        }
      } else {
        return isDaytimeIncludeA && isEveningIncludeA && isDaytimeIncludeB && isEveningIncludeB ? 0 : isDaytimeIncludeA && isEveningIncludeA ? -1 : 1
      }
    },
    unavailableSort (a, b) {
      const day = this.scheduleSorting.day
      const time = this.scheduleSorting.time
      const isDaytimeIncludeA = this.isDaytimeRideInclude(a.schedule[day].rides)
      const isEveningIncludeA = this.isEveningRideInclude(a.schedule[day].rides)

      if (time) {
        const isIncludeA = time === this.AVAILABILITY_TYPES.DAYTIME ? isDaytimeIncludeA : isEveningIncludeA
        return !a.schedule[day][time] && !isIncludeA ? -1 : 1
      } else {
        return !a.schedule[day][this.AVAILABILITY_TYPES.DAYTIME] && !isDaytimeIncludeA && !a.schedule[day][this.AVAILABILITY_TYPES.EVENING] && !isEveningIncludeA ? -1 : 1
      }
    },
    setDaySort (day) {
      let updatedRouteQuery = { ...this.$route.query }
      if (this.scheduleSorting.day === day) {
        this.scheduleSorting.day = null
        this.scheduleSorting.time = null
        delete updatedRouteQuery.daySort
        delete updatedRouteQuery.timeSort
      } else {
        this.scheduleSorting.day = day
        updatedRouteQuery = { ...updatedRouteQuery, daySort: day }
      }
      this.$router.replace({ query: updatedRouteQuery }).catch(err => err)
    },
    setTimeSort (day, time) {
      let updatedRouteQuery = { ...this.$route.query }
      if (day === this.scheduleSorting.day) {
        if (this.scheduleSorting.time === time) {
          this.scheduleSorting.time = null
          delete updatedRouteQuery.timeSort
        } else {
          this.scheduleSorting.time = time
          updatedRouteQuery = { ...updatedRouteQuery, timeSort: time }
        }
      } else {
        this.scheduleSorting.day = day
        this.scheduleSorting.time = time
        updatedRouteQuery = { ...updatedRouteQuery, timeSort: time, daySort: day }
      }
      this.$router.replace({ query: updatedRouteQuery }).catch(err => err)
    },
    changeAvailabilityFilter (value) {
      let updatedRouteQuery = { ...this.$route.query }
      if (value && value.length) {
        updatedRouteQuery = { ...updatedRouteQuery, availability: value.join(',') }
      } else {
        delete updatedRouteQuery.availability
      }

      this.$router.replace({ query: updatedRouteQuery }).catch(err => err)
    },
    isDaytimeRideInclude (rides, a) {
      if (rides && rides.length) {
        return rides.some(item => {
          const startAtHour = moment(item.startAt).hour()
          const endAtHour = moment(item.endAt).hour()
          return (startAtHour >= 4 && startAtHour <= 18) || (endAtHour >= 4 && endAtHour <= 18)
        })
      }
      return false
    },
    isEveningRideInclude (rides, a) {
      if (rides && rides.length) {
        return rides.some(item => {
          const startAtHour = moment(item.startAt).hour()
          const endAtHour = moment(item.endAt).hour()
          return startAtHour < 4 || startAtHour > 18 || endAtHour < 4 || endAtHour > 18
        })
      }
      return false
    },
    updateParams () {
      let params = {}

      let updatedRouteQuery = { ...this.$route.query }

      if (this.filters.sortFilter) {
        params.sort = this.filters.sortFilter
        updatedRouteQuery = { ...updatedRouteQuery, sort: this.filters.sortFilter }
      }

      if (this.filters.brandsFilter) {
        updatedRouteQuery = { ...updatedRouteQuery, brand: this.filters.brandsFilter }
        params.brand = this.filters.brandsFilter
      } else {
        delete updatedRouteQuery.brand
        delete params.brand
      }

      if (this.filters.regionsFilter && this.filters.regionsFilter.length) {
        updatedRouteQuery = { ...updatedRouteQuery, regions: this.filters.regionsFilter.join(',') }
        params.regions = this.filters.regionsFilter.join(',')
      } else {
        delete updatedRouteQuery.regions
        delete params.regions
      }

      this.$router.replace({ query: updatedRouteQuery }).catch(err => err)

      this.params = params
    },
    async getDriversSchedule (params = {}) {
      try {
        this.loading = true
        this.items = []

        let body = { params: { ...params, brand: params.brand ? params.brand : this.brandList.map(item => item.id).join(',') } }

        await this.request('GET', `/drivers/schedule-overview?pageSize=9999`, body, ({ data }) => {
          if (data) {
            this.items = Object.values(data)

            if (!this.isInit && !this.scheduleSorting.day) {
              this.isInit = true
              this.scheduleSorting.day = this.headersTable[1].text
              this.scheduleSorting.time = this.AVAILABILITY_TYPES.DAYTIME
            }
          }
        })
      } catch (error) {
        console.log(error, 'err')
      } finally {
        this.loading = false
      }
    },
    onChangeBrands (brandsIds) {
      this.filters.brandsFilter = brandsIds
    }
  }
}
</script>

<style lang="scss" scoped>
  .bordered-input {
    display: flex;
    align-items: center;
  }

  .date-cell-header {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .daytime-cell-header {
    border-right: 1px solid gray;
    border-top: 1px solid gray;
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    flex: 1;
  }

  .evening-cell-header {
    border-top: 1px solid gray;
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    flex: 1;
  }

  .daytime-cell-header:hover,
  .evening-cell-header:hover,
  .date-cell-header:hover {
    background: rgba(0, 0, 0, 0.0);
  }

  .daytime-cell-item {
    flex: 1;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }

  .evening-cell-item {
    flex: 1;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }

  .green {
    background: rgb(82, 227, 99);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .red {
    background: rgb(249, 57, 57);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .blue {
    background: rgb(55, 49, 236);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gray {
    background: rgb(215, 215, 216);
    color: #303030;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .driver-schedule-table {
    th {
      padding: 0 !important;
      border-right: 1px solid gray;
    }

    td {
      padding: 0 !important;
    }

    .date-header {
      cursor: pointer;
    }
  }
</style>
